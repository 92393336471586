#login-page{
  background: url('/public/images/login_background.png'), linear-gradient(90deg, #01C5C5 0%, #004C4C 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

#login-form-container{
  width: 280px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: white;
  text-align: center;
  border-radius: 0.5rem;
  padding: 2.5rem;
}